@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #5600FF;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$white: #FFFFFF;

// Update Bulma's global variables
$family-sans-serif: 'Nunito'; //,sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $purple;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $white;
$control-border-width: 2px;
$input-border-color: $beige-light;

// Modal
$modal-z: 1000;
$modal-content-width: 640px;
$modal-card-head-background-color: $white;
$modal-card-foot-background-color: $white;
$modal-card-foot-border-top: 0px;

// Import Bulma
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma-addons/src/sass/navigation-view.sass";
// or
// Import only what you need from Bulma
//@import '../node_modules/bulma/sass/utilities/_all.sass';
//@import '../node_modules/bulma/sass/base/_all.sass';
//@import '../node_modules/bulma/sass/elements/box.sass';
//@import '../node_modules/bulma/sass/elements/button.sass';
//@import '../node_modules/bulma/sass/elements/container.sass';
//@import '../node_modules/bulma/sass/elements/form.sass';
//@import '../node_modules/bulma/sass/elements/form.sass';
//@import '../node_modules/bulma/sass/elements/title.sass';
//@import '../node_modules/bulma/sass/components/navbar.sass';
//@import '../node_modules/bulma/sass/layout/hero.sass';
//@import '../node_modules/bulma/sass/layout/section.sass';
