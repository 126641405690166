body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.dropdown {
	width: 100%;
}

.dropdown-trigger {
	width: 100%;
}

.demo {
	margin: 1em;
}

.sticky {
	position: sticky;
	top: 75px;
	z-index: 100000;
}
